import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';  // Importing useTranslation for multi-language support

const bezirke = [
  'Charlottenburg-Wilmersdorf', 'Friedrichshain-Kreuzberg', 'Lichtenberg', 'Marzahn-Hellersdorf',
  'Mitte', 'Neukölln', 'Pankow', 'Reinickendorf', 'Spandau', 'Steglitz-Zehlendorf',
  'Tempelhof-Schöneberg', 'Treptow-Köpenick'
];

const AdminPreferences = () => {
  const { t } = useTranslation();
  const { id } = useParams();  // Get user ID from URL parameters
  const [formData, setFormData] = useState({
    min_rooms: '',
    max_rooms: '',
    min_size: '',
    max_size: '',
    min_price: '',
    max_price: '',
    haswbs: 'bothwbs',  // Default is 'bothwbs'
    location: [],  // Store locations as an array initially
  });
  const [errors, setErrors] = useState({});
  const [selectAll, setSelectAll] = useState(false); // To handle "Select All" checkbox
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_API_BASE_URL || 'https://homiego.de/api';

  useEffect(() => {
    const fetchAdminPreferences = async () => {
      try {
        const response = await axios.get(`${baseUrl}/admin/users/${id}/AdminPreferences`);
        if (response.data) {
          setFormData({
            min_rooms: response.data.min_rooms || '',
            max_rooms: response.data.max_rooms || '',
            min_size: response.data.min_size || '',
            max_size: response.data.max_size || '',
            min_price: response.data.min_price || '',
            max_price: response.data.max_price || '',
            haswbs: response.data.haswbs === true ? 'true' : response.data.haswbs === false ? 'false' : 'bothwbs',
            location: response.data.location || [],  // Convert from backend string to array
          });
        }
      } catch (error) {
        console.error(t('fetchAdminPreferencesError'), error);  // Translated error message
      }
    };

    fetchAdminPreferences();
  }, [id, t, baseUrl]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const handleLocationChange = (bezirk) => {
    let newLocation = [...formData.location];
    if (newLocation.includes(bezirk)) {
      newLocation = newLocation.filter((loc) => loc !== bezirk);
    } else {
      newLocation.push(bezirk);
    }
    setFormData({ ...formData, location: newLocation });
    setSelectAll(newLocation.length === bezirke.length);  // Automatically check/uncheck "Select All"
    setErrors({ ...errors, location: '' });
  };

  const handleSelectAll = () => {
    if (!selectAll) {
      setFormData({ ...formData, location: bezirke });
    } else {
      setFormData({ ...formData, location: [] });
    }
    setSelectAll(!selectAll);
  };

  const validateForm = () => {
    const newErrors = {};

    if (formData.min_rooms < 1) {
      newErrors.min_rooms = t('minRoomsValidation');
    }
    if (formData.max_rooms < 1) {
      newErrors.max_rooms = t('maxRoomsValidation');
    }
    if (formData.min_size < 1) {
      newErrors.min_size = t('minSizeValidation');
    }
    if (formData.max_size < 1) {
      newErrors.max_size = t('maxSizeValidation');
    }
    if (formData.min_price < 1) {
      newErrors.min_price = t('minPriceValidation');
    }
    if (formData.max_price < 1) {
      newErrors.max_price = t('maxPriceValidation');
    }
    if (formData.location.length === 0 || formData.location.length === bezirke.length) {
      setFormData({ ...formData, location: ['All'] });  // If none or all locations selected, set to 'All'
    } 
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const token = localStorage.getItem('token');
    if (token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };

      try {
        await axios.put(`${baseUrl}/admin/users/${id}/AdminPreferences`, formData, config);
        alert(t('AdminPreferencesSaved'));  // Translated success message
        navigate('/admin-dashboard');
      } catch (error) {
        console.error(t('saveAdminPreferencesError'), error);  // Translated error message
        alert(t('saveAdminPreferencesFailed'));  // Translated error alert
      }
    }
  };

  return (
    <div className="container mt-4">
      <h2>{t('editUserAdminPreferences')}</h2>  {/* Translated heading */}
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label className="form-label">{t('minRooms')}</label>  {/* Translated label */}
          <input
            type="number"
            className="form-control"
            name="min_rooms"
            value={formData.min_rooms}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">{t('maxRooms')}</label>  {/* Translated label */}
          <input
            type="number"
            className="form-control"
            name="max_rooms"
            value={formData.max_rooms}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">{t('minSize')}</label>  {/* Translated label */}
          <input
            type="number"
            className="form-control"
            name="min_size"
            value={formData.min_size}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">{t('maxSize')}</label>  {/* Translated label */}
          <input
            type="number"
            className="form-control"
            name="max_size"
            value={formData.max_size}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">{t('minPrice')}</label>  {/* Translated label */}
          <input
            type="number"
            className="form-control"
            name="min_price"
            value={formData.min_price}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">{t('maxPrice')}</label>  {/* Translated label */}
          <input
            type="number"
            className="form-control"
            name="max_price"
            value={formData.max_price}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="mb-3">
          <label className="form-label">{t('location')}</label>  {/* Translated label */}
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="selectAll"
              checked={selectAll}
              onChange={handleSelectAll}
            />
            <label className="form-check-label" htmlFor="selectAll">
              {t('selectAll')}
            </label>
          </div>
          {bezirke.map((bezirk, index) => (
            <div key={index} className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id={`bezirk-${index}`}
                value={bezirk}
                checked={formData.location.includes(bezirk)}
                onChange={() => handleLocationChange(bezirk)}
              />
              <label className="form-check-label" htmlFor={`bezirk-${index}`}>
                {bezirk}
              </label>
            </div>
          ))}
          {errors.location && <div className="invalid-feedback">{errors.location}</div>}
        </div>

        <div className="mb-3">
          <label className="form-label">{t('hasWBS')}</label>  {/* Translated label */}
          <div className="form-check">
            <input
              type="radio"
              className="form-check-input"
              name="haswbs"
              value="bothwbs"
              checked={formData.haswbs === 'bothwbs'}
              onChange={handleInputChange}
            />
            <label className="form-check-label">{t('bothwbs')}</label>
          </div>
          <div className="form-check">
            <input
              type="radio"
              className="form-check-input"
              name="haswbs"
              value="true"
              checked={formData.haswbs === 'true'}
              onChange={handleInputChange}
            />
            <label className="form-check-label">{t('yes')}</label>
          </div>
          <div className="form-check">
            <input
              type="radio"
              className="form-check-input"
              name="haswbs"
              value="false"
              checked={formData.haswbs === 'false'}
              onChange={handleInputChange}
            />
            <label className="form-check-label">{t('no')}</label>
          </div>
        </div>

        <button type="submit" className="btn btn-primary">{t('saveAdminPreferences')}</button>  {/* Translated button */}
      </form>
    </div>
  );
};

export default AdminPreferences;
