import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const Notifications = () => {
  const { t } = useTranslation();
  const [notificationPreference, setNotificationPreference] = useState('email'); // Default is 'email'
  const [preferredLanguage, setPreferredLanguage] = useState('en'); // Default is 'en'
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const baseUrl = process.env.REACT_APP_API_BASE_URL || 'https://homiego.de/api';

  // Fetch user notifications settings from API when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/user/notifications`);
        
        if (response.status === 200) {
          setNotificationPreference(response.data.notificationPreference);
          setPreferredLanguage(response.data.preferredLanguage);
        } else {
          console.error('Unexpected response status:', response.status);
        }
        
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user notifications settings:', error);
        setLoading(false);
        setError(true);
      }
    };

    fetchData();
  }, [baseUrl]);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const updatedNotifications = {
      notification_preference: notificationPreference,  // Match backend keys
      preferred_language: preferredLanguage            // Match backend keys
    };

    try {
      const response = await axios.post(`${baseUrl}/user/notifications`, updatedNotifications);
      
      if (response.status === 200) {
        setShowAlert(true);
        setError(false);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      }
    } catch (error) {
      console.error('Error updating notifications settings:', error);
      setError(true);
    }
  };

  if (loading) {
    return <div>{t('loading')}</div>;
  }

  return (
    <div className="container mt-4">
      <h2>{t('notifications')}</h2>

      {/* Success Alert */}
      {showAlert && (
        <div className="alert alert-success" role="alert" aria-live="assertive">
          {t('notificationsUpdated')}
        </div>
      )}

      {/* Error Alert */}
      {error && (
        <div className="alert alert-danger" role="alert" aria-live="assertive">
          {t('errorUpdatingNotifications')}
        </div>
      )}

      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="notificationPreference" className="form-label">{t('notificationPreference')}</label>
          <select
            id="notificationPreference"
            className="form-select"
            value={notificationPreference}
            onChange={(e) => setNotificationPreference(e.target.value)}
          >
            <option value="email">{t('email')}</option>
            <option value="whatsapp">{t('whatsapp')}</option>
            <option value="both">{t('both')}</option>
            <option value="none">{t('none')}</option>
          </select>
        </div>

        <div className="mb-3">
          <label htmlFor="preferredLanguage" className="form-label">{t('preferredLanguage')}</label>
          <select
            id="preferredLanguage"
            className="form-select"
            value={preferredLanguage}
            onChange={(e) => setPreferredLanguage(e.target.value)}
          >
            <option value="en">{t('english')}</option>
            <option value="de">{t('german')}</option>
            <option value="ar">{t('arabic')}</option>
            <option value="uk">{t('ukrainian')}</option>
            <option value="es">{t('spanish')}</option>
          </select>
        </div>

        <div className="alert alert-info">
          {t('manualApplyNotice')}
        </div>

        <button type="submit" className="btn btn-primary">{t('saveNotifications')}</button>
      </form>
    </div>
  );
};

export default Notifications;
