import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import translationDE from './locales/de/translation.json';
import translationAR from './locales/ar/translation.json';
import translationES from './locales/es/translation.json';  // Importing Spanish translation
import translationUK from './locales/uk/translation.json';  // Importing Ukrainian translation

// The translations
const resources = {
  en: {
    translation: translationEN
  },
  de: {
    translation: translationDE
  },
  ar: {
    translation: translationAR
  },
  es: {
    translation: translationES  // Adding Spanish translations
  },
  uk: {
    translation: translationUK  // Adding Ukrainian translations
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en', // default language
    fallbackLng: 'en', // fallback language if the translation is missing
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
