import React, { useEffect, useContext } from 'react';
import 'font-awesome/css/font-awesome.min.css'; // Import Font Awesome CSS here
import { Route, Routes, Navigate} from 'react-router-dom';
import UserEdit from './adminComponents/UserEdit';
import Register from './sharedComponents/Register.js';
import Login from './sharedComponents/Login';
import Profile from './customerComponents/Profile';
import AppliedListings from './customerComponents/AppliedListings';
import EmailVerification from './sharedComponents/EmailVerification';
import Preferences from './customerComponents/Preferences';
import PayPalPayment from './customerComponents/PayPalPayment';
import { AuthContext, AuthProvider } from './sharedComponents/AuthContext';
import NavBar from './sharedComponents/NavBar';
import './App.css';
import AdminDashboard from './adminComponents/AdminDashboard';
import CustomerDashboard from './customerComponents/CustomerDashboard';
import AdminPreferences from './adminComponents/AdminPreferences';
import AdminAppliedListings from './adminComponents/AdminAppliedListings';
import ResetPassword from './sharedComponents/ResetPassword';
import AdminListings from './adminComponents/AdminListings';  // Import the AdminListings component
import AdminWebsites from './adminComponents/AdminWebsites';
import Footer from './sharedComponents/Footer.js'
import WelcomePage from './sharedComponents/WelcomePage.js';
import AboutUs from './footComponents/AboutUs.js';
import PrivacyPolicy from './footComponents/PrivacyPolicy.js';
import FAQ from './footComponents/FAQ.js';
import Contact from './footComponents/Contact.js';
import Notifications from './customerComponents/Notifications.js'
import CustomerAnalytics from './adminComponents/CustomerAnalytics.js'
function App() {
  useEffect(() => {
    document.title = "HomieGo"; // Set your desired title here
  }, []); // This will run only once when the component mounts


  return (
    <AuthProvider>
      <div className="App">
        <NavBar />
        <div className="container mt-5 pt-4">
          <Routes>
          <Route path="/" element={<WelcomePage />} />
          <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
            <Route path="/applied-listings" element={<PrivateRoute><AppliedListings /></PrivateRoute>} />
            <Route path="/preferences" element={<PrivateRoute><Preferences /></PrivateRoute>} />
            <Route path="/confirm_email/:token" element={<EmailVerification />} />
            <Route path="/paypal-payment" element={<PrivateRoute><PayPalPayment /></PrivateRoute>} />
            <Route path="/admin-dashboard" element={<PrivateRoute adminOnly><AdminDashboard /></PrivateRoute>} />
            <Route path="/customer-dashboard" element={<PrivateRoute><CustomerDashboard /></PrivateRoute>} />
            <Route path="/admin/user/:id/edit" element={<UserEdit />} />
            <Route path="/admin/user/:id/preferences" element={<AdminPreferences />} />
            <Route path="/reset_password/:token" element={<ResetPassword />} />
            <Route path="/admin/listings" element={<AdminListings />} /> {/* Add this route */}
            <Route path="/admin/websites" element={<AdminWebsites />} />
            <Route path="/about-us" element={<AboutUs />} /> {/* Add About Us route */}
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/admin/customers/analytics" element={<CustomerAnalytics />} />
          <Route path="/admin/users/:id/applications" element={<AdminAppliedListings />} />  {/* Add AppliedListings route */}

            </Routes>
        </div>
        <Footer />
      </div>
    </AuthProvider>
  );
}

const PrivateRoute = ({ children, adminOnly }) => {
  const { isAuthenticated, isAdmin } = useContext(AuthContext);


  // Add a loading state to prevent routing until auth states are determined
  if (isAuthenticated === null) {
    return <div>Loading...</div>;
  }

  // If the user is not authenticated, redirect to login
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  // If the route is admin-only and the user is not admin, redirect to customer-dashboard
  if (adminOnly && !isAdmin) {
    return <Navigate to="/customer-dashboard" />;
  }

  // If the user is admin but the route is not admin-only, redirect to admin-dashboard
  if (!adminOnly && isAdmin) {
    return <Navigate to="/admin-dashboard" />;
  }

  return children;
};

export default App;
