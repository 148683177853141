import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../App.css';
import { useTranslation } from 'react-i18next';  // Import useTranslation hook

const Profile = () => {
    const { t } = useTranslation();
    const [, setProfile] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        birth_date: '',
        gender: '',
        email: '',
        phone_number: '',
        number_of_persons: '',
        message: '',
        ishandycap: 'No',  // Default value is 'No'
        haswbs: 'No',  // Default value is 'No'
        wbs_expire_date: '',
        wbs_number_of_rooms: '',
        wbs_type: ''
    });
    const [errors, setErrors] = useState({});

    const baseUrl = process.env.REACT_APP_API_BASE_URL || 'https://homiego.de/api';

    useEffect(() => {
        const fetchProfile = async () => {
            const token = localStorage.getItem('token');
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };

            try {
                const response = await axios.get(`${baseUrl}/profile`, config);
                setProfile(response.data);
                setFormData({
                    ...response.data,
                    ishandycap: response.data.ishandycap ? 'Yes' : 'No',
                    haswbs: response.data.haswbs ? 'Yes' : 'No',
                    wbs_expire_date: response.data.wbs_expire_date ? response.data.wbs_expire_date.split('T')[0] : '',
                });
                setProfile(response.data); // You can use profile here if needed in the future
            } catch (error) {
                console.error(t('errorFetchingProfile'), error);
            }
        };

        fetchProfile();
    }, [t, baseUrl]);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({ ...formData, [name]: type === 'radio' ? (checked ? value : formData[name]) : value });
        setErrors({ ...errors, [name]: '' });  // Clear the error when the user starts typing
    };

    const validateForm = () => {
        const newErrors = {};
        const today = new Date();

        // Validation rules
        if (formData.first_name.trim().length < 2) {
            newErrors.first_name = t('firstNameValidation');
        }
        if (formData.last_name.trim().length < 2) {
            newErrors.last_name = t('lastNameValidation');
        }
        if (formData.phone_number && !/^\+?[0-9]{7,15}$/.test(formData.phone_number)) {
            newErrors.phone_number = t('invalidPhoneNumber');
        }
        if (formData.number_of_persons <= 0) {
            newErrors.number_of_persons = t('numberOfPersonsValidation');
        }
        if (formData.birth_date && new Date(formData.birth_date) >= today) {
            newErrors.birth_date = t('invalidBirthDate');
        }
        
        // Validate haswbs and ishandycap (must be 'Yes' or 'No')
        if (formData.haswbs !== 'Yes' && formData.haswbs !== 'No') {
            newErrors.haswbs = t('invalidHasWBS');
        }
        if (formData.ishandycap !== 'Yes' && formData.ishandycap !== 'No') {
            newErrors.ishandycap = t('invalidHandicap');
        }

        // If WBS is selected, check the dependent fields
        if (formData.haswbs === 'Yes') {
            if (!formData.wbs_expire_date || new Date(formData.wbs_expire_date) < today) {
                newErrors.wbs_expire_date = t('invalidWBSExpireDate');
            }
            if (!formData.wbs_number_of_rooms || formData.wbs_number_of_rooms <= 0) {
                newErrors.wbs_number_of_rooms = t('invalidWBSNumberOfRooms');
            }
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;
    
        const token = localStorage.getItem('token');
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
    
        // Prepare data for submission
        const dataToSend = {
            ...formData,
            ishandycap: formData.ishandycap === 'Yes', // Convert 'Yes'/'No' to true/false
            haswbs: formData.haswbs === 'Yes', // Convert 'Yes'/'No' to true/false
            number_of_persons: parseInt(formData.number_of_persons, 10), // Convert to integer
            wbs_expire_date: formData.wbs_expire_date === '' ? null : formData.wbs_expire_date, // Handle empty date
            wbs_number_of_rooms: formData.wbs_number_of_rooms === '' ? null : parseInt(formData.wbs_number_of_rooms, 10), // Convert or set to null
            wbs_type: formData.wbs_type === '' ? null : formData.wbs_type // Set to null if empty
        };
    
        try {
            await axios.put(`${baseUrl}/profile`, dataToSend, config);
            setProfile(dataToSend);
            setIsEditing(false);
            alert(t('profileUpdated'));  // Translated success message
        } catch (error) {
            console.error(t('errorUpdatingProfile'), error);
        }
    };
    
    return (
        <div className="container">
            <div className="profile-container">
                <h2>{t('profile')}</h2>
                {isEditing ? (
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label>{t('firstName')}*:</label>
                            <input
                                type="text"
                                className={`form-control ${errors.first_name ? 'is-invalid' : ''}`}
                                name="first_name"
                                value={formData.first_name}
                                onChange={handleInputChange}
                                required
                            />
                            {errors.first_name && <div className="invalid-feedback">{errors.first_name}</div>}
                        </div>
                        <div className="form-group">
                            <label>{t('lastName')}*:</label>
                            <input
                                type="text"
                                className={`form-control ${errors.last_name ? 'is-invalid' : ''}`}
                                name="last_name"
                                value={formData.last_name}
                                onChange={handleInputChange}
                                required
                            />
                            {errors.last_name && <div className="invalid-feedback">{errors.last_name}</div>}
                        </div>
                        <div className="form-group">
                            <label>{t('birthDate')}*:</label>
                            <input
                                type="date"
                                className={`form-control ${errors.birth_date ? 'is-invalid' : ''}`}
                                name="birth_date"
                                value={formData.birth_date}
                                onChange={handleInputChange}
                                required
                            />
                            {errors.birth_date && <div className="invalid-feedback">{errors.birth_date}</div>}
                        </div>
                        <div className="form-group">
                            <label>{t('gender')}*:</label>
                            <select
                                className="form-control"
                                name="gender"
                                value={formData.gender}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="male">{t('male')}</option>
                                <option value="female">{t('female')}</option>
                                <option value="other">{t('other')}</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>{t('email')}*:</label>
                            <input
                                type="email"
                                className="form-control"
                                name="email"
                                value={formData.email}
                                readOnly
                            />
                        </div>
                        <div className="form-group">
                            <label>{t('phoneNumber')}*:</label>
                            <input
                                type="text"
                                className={`form-control ${errors.phone_number ? 'is-invalid' : ''}`}
                                name="phone_number"
                                value={formData.phone_number}
                                onChange={handleInputChange}
                                required
                            />
                            {errors.phone_number && <div className="invalid-feedback">{errors.phone_number}</div>}
                        </div>
                        <div className="form-group">
                            <label>{t('numberOfPersons')}*:</label>
                            <input
                                type="number"
                                className={`form-control ${errors.number_of_persons ? 'is-invalid' : ''}`}
                                name="number_of_persons"
                                value={formData.number_of_persons}
                                onChange={handleInputChange}
                                required
                            />
                            {errors.number_of_persons && <div className="invalid-feedback">{errors.number_of_persons}</div>}
                        </div>
                        <div className="form-group">
                            <label>{t('message')}:</label>
                            <textarea
                                className="form-control"
                                name="message"
                                value={formData.message}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>{t('handicap')}*:</label>
                            <div>
                                <label className="form-check-label me-3">
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        name="ishandycap"
                                        value="Yes"
                                        checked={formData.ishandycap === 'Yes'}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    {t('yes')}
                                </label>
                                <label className="form-check-label">
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        name="ishandycap"
                                        value="No"
                                        checked={formData.ishandycap === 'No'}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    {t('no')}
                                </label>
                            </div>
                            {errors.ishandycap && <div className="invalid-feedback">{errors.ishandycap}</div>}
                        </div>
                        <div className="form-group">
                            <label>{t('hasWBS')}*:</label>
                            <div>
                                <label className="form-check-label me-3">
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        name="haswbs"
                                        value="Yes"
                                        checked={formData.haswbs === 'Yes'}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    {t('yes')}
                                </label>
                                <label className="form-check-label">
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        name="haswbs"
                                        value="No"
                                        checked={formData.haswbs === 'No'}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    {t('no')}
                                </label>
                            </div>
                            {errors.haswbs && <div className="invalid-feedback">{errors.haswbs}</div>}
                        </div>
                        {formData.haswbs === 'Yes' && (
                            <>
                                <div className="form-group">
                                    <label>{t('wbsExpireDate')}*:</label>
                                    <input
                                        type="date"
                                        className={`form-control ${errors.wbs_expire_date ? 'is-invalid' : ''}`}
                                        name="wbs_expire_date"
                                        value={formData.wbs_expire_date}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    {errors.wbs_expire_date && <div className="invalid-feedback">{errors.wbs_expire_date}</div>}
                                </div>
                                <div className="form-group">
                                    <label>{t('wbsNumberOfRooms')}*:</label>
                                    <input
                                        type="number"
                                        className={`form-control ${errors.wbs_number_of_rooms ? 'is-invalid' : ''}`}
                                        name="wbs_number_of_rooms"
                                        value={formData.wbs_number_of_rooms}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    {errors.wbs_number_of_rooms && <div className="invalid-feedback">{errors.wbs_number_of_rooms}</div>}
                                </div>
                                <div className="form-group">
                                    <label>{t('wbsType')}*:</label>
                                    <select
                                        className="form-control"
                                        name="wbs_type"
                                        value={formData.wbs_type}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <option value="WBS140">WBS 140</option>
                                        <option value="WBS160">WBS 160</option>
                                        <option value="WBS180">WBS 180</option>
                                        <option value="WBS220">WBS 220</option>
                                    </select>
                                </div>
                            </>
                        )}
                        <button type="submit" className="btn btn-primary">{t('save')}</button>
                        <button type="button" className="btn btn-secondary" onClick={() => setIsEditing(false)}>{t('cancel')}</button>
                    </form>
                ) : (
                    <div className="profile-details">
                        {/* Profile details section */}
                        <button className="btn btn-primary" onClick={() => setIsEditing(true)}>{t('edit')}</button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Profile;
