import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { useTranslation } from 'react-i18next';
import '../App.css';
import './Register.css';

const Register = () => {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [gender, setGender] = useState('male');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [phoneNumber, setPhoneNumber] = useState('');
  const [numberOfPersons, setNumberOfPersons] = useState('');
  const [message, setMessage] = useState('');

  const [validationErrors, setValidationErrors] = useState({});
  const [emailInUse, setEmailInUse] = useState(false); // State for email-in-use error
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    special: false,
  }); // State to track password criteria

  const { register } = useContext(AuthContext);
  const navigate = useNavigate();

  const validateForm = () => {
    const errors = {};
    const today = new Date();
    const passwordCriteria = [];

    if (!firstName) errors.firstName = t('firstNameRequired');
    if (!lastName) errors.lastName = t('lastNameRequired');
    if (!birthDate) errors.birthDate = t('birthDateRequired');
    else if (new Date(birthDate) >= today) errors.birthDate = t('birthDateInPast');

    if (!email) errors.email = t('emailRequired');

    // Password validation
    if (!password) {
      errors.password = t('passwordRequired');
    } else {
      if (password.length < 8) {
        passwordCriteria.push(t('passwordMinLength')); // At least 8 characters
      }
      if (!/[A-Z]/.test(password)) {
        passwordCriteria.push(t('passwordUppercase')); // At least one uppercase letter
      }
      if (!/[a-z]/.test(password)) {
        passwordCriteria.push(t('passwordLowercase')); // At least one lowercase letter
      }
      if (!/[0-9]/.test(password)) {
        passwordCriteria.push(t('passwordNumber')); // At least one number
      }
      if (!/[@$!%*?&]/.test(password)) {
        passwordCriteria.push(t('passwordSpecial')); // At least one special character
      }
      if (passwordCriteria.length > 0) {
        errors.password = passwordCriteria.join(', '); // Combine messages into one
      }
    }

    if (!numberOfPersons || numberOfPersons < 1) errors.numberOfPersons = t('numberOfPersonsMin');

    return errors;
  };

  // Function to check password criteria dynamically
  const checkPasswordCriteria = (password) => {
    setPasswordCriteria({
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /\d/.test(password),
      special: /[!@#$%^&*]/.test(password),
    });
  };

  const handleRegister = async (e) => {
    e.preventDefault();
  
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }
  
    // If email is not in use, prepare form data for registration
    const formData = {
      first_name: firstName,
      last_name: lastName,
      birth_date: birthDate,
      gender: gender,
      email: email,
      password: password,
      phone_number: phoneNumber,
      number_of_persons: numberOfPersons,
      message: message,
    };
  
    const registrationResult = await register(formData);
  
    if (registrationResult === "Email already registered") {
      setEmailInUse(true);
      setValidationErrors({ ...validationErrors, email: t('emailAlreadyRegistered') });
      return;  // Stop the registration process
    } else if (registrationResult === true) {
      // If registration is successful
      navigate('/login', { state: { message: t('checkYourEmail') } });
    } else {
      console.log(t('registrationFailed'));
    }
  };
  

  return (
    <div className="container">
      <form onSubmit={handleRegister} className="mt-4">
        <h2>{t('register')}</h2>
  
        <div className="mb-3">
          <label className="form-label">{t('firstName')}*:</label>
          <input
            type="text"
            className={`form-control ${validationErrors.firstName ? 'is-invalid' : ''}`}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
          {validationErrors.firstName && (
            <div className="invalid-feedback">{validationErrors.firstName}</div>
          )}
        </div>
  
        <div className="mb-3">
          <label className="form-label">{t('lastName')}*:</label>
          <input
            type="text"
            className={`form-control ${validationErrors.lastName ? 'is-invalid' : ''}`}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
          {validationErrors.lastName && (
            <div className="invalid-feedback">{validationErrors.lastName}</div>
          )}
        </div>
  
        <div className="mb-3">
          <label className="form-label">{t('birthDate')}*:</label>
          <input
            type="date"
            className={`form-control ${validationErrors.birthDate ? 'is-invalid' : ''}`}
            value={birthDate}
            onChange={(e) => setBirthDate(e.target.value)}
            required
          />
          {validationErrors.birthDate && (
            <div className="invalid-feedback">{validationErrors.birthDate}</div>
          )}
        </div>
  
        <div className="mb-3">
          <label className="form-label">{t('gender')}*:</label>
          <select
            className={`form-control ${validationErrors.gender ? 'is-invalid' : ''}`}
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            required
          >
            <option value="male">{t('male')}</option>
            <option value="female">{t('female')}</option>
            <option value="other">{t('other')}</option>
          </select>
        </div>
  
        <div className="mb-3">
          <label className="form-label">{t('email')}*:</label>
          <input
            type="email"
            className={`form-control ${validationErrors.email ? 'is-invalid' : ''} ${emailInUse ? 'is-invalid' : ''}`}
            value={email}
            onChange={(e) => { setEmail(e.target.value); setEmailInUse(false); }}
            required
          />
          {validationErrors.email && (
            <div className="invalid-feedback">{validationErrors.email}</div>
          )}
          {emailInUse && (
            <div className="invalid-feedback">{t('emailAlreadyRegistered')}</div>
          )}
        </div>
  
        <div className="mb-3">
          <label className="form-label">{t('password')}*:</label>
          <div className="input-group">
            <input
              type={showPassword ? 'text' : 'password'}
              className={`form-control ${validationErrors.password ? 'is-invalid' : ''}`}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                checkPasswordCriteria(e.target.value); // Check criteria on input change
              }}
              required
            />
            <span className="input-group-text" onClick={() => setShowPassword(!showPassword)} style={{ cursor: 'pointer' }}>
              <i className={`fa ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}></i>
            </span>
          </div>
          {validationErrors.password && (
            <div className="invalid-feedback">{validationErrors.password}</div>
          )}
          <div className="form-text">{t('passwordCriteria')}</div>
          <ul className="text-danger">
            <li className={passwordCriteria.length ? 'text-success' : ''}>{t('passwordMinLength')}: 8 characters</li>
            <li className={passwordCriteria.uppercase ? 'text-success' : ''}>{t('passwordUppercase')}: At least 1 uppercase letter</li>
            <li className={passwordCriteria.lowercase ? 'text-success' : ''}>{t('passwordLowercase')}: At least 1 lowercase letter</li>
            <li className={passwordCriteria.number ? 'text-success' : ''}>{t('passwordNumber')}: At least 1 number</li>
            <li className={passwordCriteria.special ? 'text-success' : ''}>{t('passwordSpecial')}: At least 1 special character (@$!%*?&)</li>
          </ul>
        </div>
  
        <div className="mb-3">
          <label className="form-label">{t('numberOfPersons')}*:</label>
          <input
            type="number"
            className={`form-control ${validationErrors.numberOfPersons ? 'is-invalid' : ''}`}
            value={numberOfPersons}
            onChange={(e) => setNumberOfPersons(e.target.value)}
            required
          />
          {validationErrors.numberOfPersons && (
            <div className="invalid-feedback">{validationErrors.numberOfPersons}</div>
          )}
        </div>
  
        <div className="mb-3">
          <label className="form-label">{t('phoneNumber')} ({t('optional')})</label>
          <input
            type="text"
            className={`form-control ${validationErrors.phoneNumber ? 'is-invalid' : ''}`}
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          {validationErrors.phoneNumber && (
            <div className="invalid-feedback">{validationErrors.phoneNumber}</div>
          )}
        </div>
  
        <div className="mb-3">
          <label className="form-label">{t('message')} ({t('optional')})</label>
          <textarea
            className={`form-control ${validationErrors.message ? 'is-invalid' : ''}`}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          {validationErrors.message && (
            <div className="invalid-feedback">{validationErrors.message}</div>
          )}
        </div>
  
        <button type="submit" className="btn btn-primary">{t('register')}</button>
      </form>
    </div>
  );
};

export default Register;
