import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ResetPassword = () => {
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(''); // Capture token via an input field
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_API_BASE_URL || 'https://homiego.de/api';

  const handleSubmit = async (e) => {
    e.preventDefault();

    const passwordStrengthRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$/;

    if (password !== confirmPassword) {
      setError(t('passwordsDoNotMatch'));
      return;
    }

    if (!passwordStrengthRegex.test(password)) {
      setError(t('passwordMustContain'));
      return;
    }

    setLoading(true);
    try {
      // Send token in the request body instead of the URL
      await axios.post(`${baseUrl}/reset_password`, { token, password });
      setSuccess(t('passwordResetSuccessfully'));
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setError(t('tokenInvalidOrExpired'));
      } else {
        setError(t('failedToResetPassword'));
      }
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setError(''); // Clear error message when typing
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setError(''); // Clear error message when typing
  };

  const handleTokenChange = (e) => {
    setToken(e.target.value);
    setError(''); // Clear error message when typing
  };

  return (
    <div className="container" dir={t('direction') || 'ltr'}>
      <h2>{t('resetPassword')}</h2>
      {error && <div className="alert alert-danger">{error}</div>}
      {success && (
        <div className="alert alert-success">
          {success}
          <button onClick={() => navigate('/login')} className="btn btn-success mt-2">
            {t('goToLogin')}
          </button>
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label className="form-label">{t('token')}</label>
          <input
            type="text"
            className="form-control"
            value={token}
            onChange={handleTokenChange}
            required
            placeholder={t('enterTokenFromEmail')}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">{t('newPassword')}</label>
          <input
            type="password"
            className="form-control"
            value={password}
            onChange={handlePasswordChange}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">{t('confirmPassword')}</label>
          <input
            type="password"
            className="form-control"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary" disabled={loading}>
          {loading ? t('resetting') : t('resetPassword')}
        </button>
      </form>
    </div>
  );
};

export default ResetPassword;
