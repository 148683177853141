import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';  // Import useTranslation hook

const PayPalPayment = () => {
    const { t } = useTranslation();  // Initialize useTranslation for multi-language support
  // Fallback URL in case environment variable isn't set
  //const baseUrl = process.env.REACT_APP_API_BASE_URL || 'https://homiego.de/api';


    useEffect(() => {
        const clientID = process.env.PAYPAL_CLIENT_ID;
        const script = document.createElement('script');
        script.src = `https://www.paypal.com/sdk/js?client-id=${clientID}`;
        script.async = true;
        script.onload = () => {
            window.paypal.Buttons({
                createOrder: function(data, actions) {
                    return fetch('/create-paypal-payment', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                        },
                        body: JSON.stringify({
                            amount: 1000  // Amount in cents (e.g., €10.00)
                        })
                    }).then(function(response) {
                        return response.json();
                    }).then(function(orderData) {
                        return orderData.paymentID;
                    });
                },
                onApprove: function(data, actions) {
                    return fetch('/execute-paypal-payment', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            paymentID: data.paymentID,
                            payerID: data.payerID
                        })
                    }).then(function(response) {
                        return response.json();
                    }).then(function(orderData) {
                        alert(t('paymentSuccess'));  // Translation for success message
                    });
                }
            }).render('#paypal-button-container');
        };
        document.body.appendChild(script);
    }, [t]);

    return (
        <div>
            <h2>{t('payForProduct')}</h2>  {/* Translated heading */}
            <div id="paypal-button-container"></div>
        </div>
    );
};

export default PayPalPayment;
