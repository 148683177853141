import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const AdminWebsites = () => {
    const { t } = useTranslation();
    const [websites, setWebsites] = useState([]);
    const [error, setError] = useState('');
    const baseUrl = process.env.REACT_APP_API_BASE_URL || 'https://homiego.de/api';

    useEffect(() => {
        const fetchWebsites = async () => {
            const token = localStorage.getItem('token');
            if (!token) {
                setError(t('noTokenFound'));  // Error if token is missing
                return;
            }

            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };

            try {
                const response = await axios.get(`${baseUrl}/admin/websites`, config);
                if (Array.isArray(response.data)) {
                    setWebsites(response.data);
                } else {
                    console.error('Unexpected response format:', response.data);
                    setWebsites([]);  // Fallback to empty array if response is not as expected
                }
            } catch (error) {
                console.error(t('errorFetchingWebsites'), error);
                setError(t('errorFetchingWebsites'));
            }
        };

        fetchWebsites();
    }, [t, baseUrl]);

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Typography variant="h4" component="h1" gutterBottom>
                {t('adminWebsites')}
            </Typography>

            {error && <Typography variant="h6" color="error">{error}</Typography>}

            {Array.isArray(websites) && websites.length > 0 ? (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('id')}</TableCell>
                                <TableCell>{t('name')}</TableCell>
                                <TableCell>{t('url')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {websites.map((website) => (
                                <TableRow key={website.id}>
                                    <TableCell>{website.id}</TableCell>
                                    <TableCell>{website.name}</TableCell>
                                    <TableCell>{website.url}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography variant="h6" color="textSecondary" align="center" sx={{ mt: 2 }}>
                    {t('noWebsitesAvailable')}
                </Typography>
            )}
        </Container>
    );
};

export default AdminWebsites;
