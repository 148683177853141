import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../App.css';  // Ensure your CSS file path is correct

// Define available Bezirke (districts) and their corresponding postal codes (PLZ)
const bezirkToPLZ = {
  'Charlottenburg-Wilmersdorf': [10585, 10623, 10707, 10709, 10711, 14052, 14055],
  'Friedrichshain-Kreuzberg': [10243, 10245, 10961, 10963, 10969],
  'Lichtenberg': [10365, 10367, 10369, 13051, 13053, 13055],
  'Marzahn-Hellersdorf': [12619, 12621, 12623, 12627, 12629],
  'Mitte': [10115, 10117, 10119, 10557, 10559],
  'Neukölln': [12043, 12045, 12047, 12049, 12051],
  'Pankow': [10405, 10407, 10409, 13125, 13127],
  'Reinickendorf': [13403, 13405, 13407, 13409, 13435, 13437],
  'Spandau': [13581, 13583, 13585, 13587, 13589, 14052],
  'Steglitz-Zehlendorf': [12157, 12161, 12163, 12203, 12205],
  'Tempelhof-Schöneberg': [10777, 10779, 10781, 10823, 10825, 10827],
  'Treptow-Köpenick': [12435, 12437, 12439, 12524, 12526]
};

const Filters = ({ filters, handleFilterChange, applyFilters, setFilters }) => {
  const { t } = useTranslation();
  const [selectAll, setSelectAll] = useState(false);
  const [locationVisible, setLocationVisible] = useState(true);

  const clearFilters = () => {
    setFilters({
      min_price: '',
      max_price: '',
      min_size: '',
      max_size: '',
      min_rooms: '',
      max_rooms: '',
      wbs: 'bothwbs',
      location: []
    });
    setSelectAll(false);
  };

  const handleLocationChange = (e) => {
    const { value, checked } = e.target;
    let newLocations = [...filters.location];

    if (value === 'All') {
      if (checked) {
        newLocations = Object.values(bezirkToPLZ).flat();
      } else {
        newLocations = [];
      }
      setSelectAll(checked);
    } else {
      const plzArray = bezirkToPLZ[value] || [];
      if (checked) {
        newLocations.push(...plzArray);
      } else {
        newLocations = newLocations.filter(loc => !plzArray.includes(loc));
      }
      setSelectAll(newLocations.length === Object.values(bezirkToPLZ).flat().length);
    }
    handleFilterChange({ target: { name: 'location', value: newLocations } });
  };

  const toggleLocation = () => {
    setLocationVisible(!locationVisible);
  };

  return (
    <div className="filters mb-4">
      <h5>{t('filters')}</h5>
      <div className="row">
        <div className="col-md-3">
          <label>{t('minPrice')}</label>
          <input type="number" className="form-control" name="min_price" value={filters.min_price} onChange={handleFilterChange} />
        </div>
        <div className="col-md-3">
          <label>{t('maxPrice')}</label>
          <input type="number" className="form-control" name="max_price" value={filters.max_price} onChange={handleFilterChange} />
        </div>
        <div className="col-md-3">
          <label>{t('minSize')}</label>
          <input type="number" className="form-control" name="min_size" value={filters.min_size} onChange={handleFilterChange} />
        </div>
        <div className="col-md-3">
          <label>{t('maxSize')}</label>
          <input type="number" className="form-control" name="max_size" value={filters.max_size} onChange={handleFilterChange} />
        </div>
        <div className="col-md-3">
          <label>{t('minRooms')}</label>
          <input type="number" className="form-control" name="min_rooms" value={filters.min_rooms} onChange={handleFilterChange} />
        </div>
        <div className="col-md-3">
          <label>{t('maxRooms')}</label>
          <input type="number" className="form-control" name="max_rooms" value={filters.max_rooms} onChange={handleFilterChange} />
        </div>
        <div className="col-md-3">
          <label>{t('wbs')}</label>
          <select className="form-control" name="wbs" value={filters.wbs} onChange={handleFilterChange}>
            <option value="bothwbs">{t('bothwbs')}</option>
            <option value="true">{t('yes')}</option>
            <option value="false">{t('no')}</option>
          </select>
        </div>
        <div className="col-md-12 mt-3">
          <button className="btn btn-secondary mb-2 location-toggle-btn" onClick={toggleLocation}>
            {t('selectLocation')} <span className={locationVisible ? 'arrow-up' : 'arrow-down'}></span>
          </button>
          {locationVisible && (
            <div className="location-checkboxes">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="selectAll"
                  value="All"
                  checked={selectAll}
                  onChange={handleLocationChange}
                />
                <label className="form-check-label" htmlFor="selectAll">{t('selectAll')}</label>
              </div>
              {Object.keys(bezirkToPLZ).map((bezirk, index) => (
                <div key={index} className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={`bezirk-${index}`}
                    value={bezirk}
                    onChange={handleLocationChange}
                  />
                  <label className="form-check-label" htmlFor={`bezirk-${index}`}>{bezirk}</label>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="col-md-6 mt-3">
          <button className="btn btn-primary mt-4" onClick={applyFilters}>{t('applyFilters')}</button>
        </div>
        <div className="col-md-6 mt-3">
          <button className="btn btn-secondary mt-4" onClick={clearFilters}>{t('clearFilters')}</button>
        </div>
      </div>
    </div>
  );
};

export default Filters;
