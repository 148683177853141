import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Container,
  IconButton,
  Button  // Import the Button component
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';  // Import useTranslation

function AdminDashboard() {
  const { t } = useTranslation();  // Initialize useTranslation
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm] = useState('');
  const [filters] = useState({
    admin: false,
    customer: false, // New filter for customer (non-admin)
    paid: false,
    hasWbs: false,
    confirmed: false,
  });
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_API_BASE_URL || 'https://homiego.de/api';

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${baseUrl}/admin/users`);
        console.log('Fetched users:', response.data);  // Log API response to verify it contains an array
        if (Array.isArray(response.data)) {
          setUsers(response.data);  // Only set users if response.data is an array
          setFilteredUsers(response.data);
        } else {
          setUsers([]);  // Ensure users is always an array
        }
      } catch (error) {
        console.error(t('fetchUsersError'), error);  // Translated error message
      }
    };
    fetchUsers();
  }, [t, baseUrl]);

  // Apply search and filters
  useEffect(() => {
    let filtered = users;

    // Filter by search term (name or email)
    if (searchTerm) {
      filtered = filtered.filter((user) =>
        user.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Filter by admin
    if (filters.admin) {
      filtered = filtered.filter((user) => user.is_admin);
    }

    // Filter by customer (non-admin)
    if (filters.customer) {
      filtered = filtered.filter((user) => !user.is_admin);
    }

    // Filter by paid status
    if (filters.paid) {
      filtered = filtered.filter((user) => user.has_paid);
    }

    // Filter by WBS status
    if (filters.hasWbs) {
      filtered = filtered.filter((user) => user.hasWbs);
    }

    // Filter by email confirmation status
    if (filters.confirmed) {
      filtered = filtered.filter((user) => user.email_confirmed);
    }

    setFilteredUsers(filtered);
  }, [searchTerm, filters, users]);

  const handleDelete = async (userId) => {
    const confirmDelete = window.confirm(t('confirmDelete'));  // Translated confirm message
    if (confirmDelete) {
      try {
        await axios.delete(`${baseUrl}/admin/users/${userId}`);
        setUsers(users.filter(user => user.id !== userId)); // Remove user from state
      } catch (error) {
        console.error(t('deleteUserError'), error);  // Translated error message
      }
    }
  };

  const handleEdit = (userId) => {
    navigate(`/admin/user/${userId}/edit`);
  };

  // Navigate to AdminListings when the button is clicked
  const handleViewListings = () => {
    navigate('/admin/listings');  // Redirect to AdminListings component
  };

  // Navigate to CustomerAnalytics when the button is clicked
  const handleViewAnalytics = () => {
    navigate('/admin/customers/analytics');  // Redirect to CustomerAnalytics component
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        {t('adminDashboard')}
      </Typography>

      {/* Add a button to navigate to the listings page */}
      <Button
        variant="contained"
        color="primary"
        sx={{ mb: 2, mr: 2 }}  // Add some margin-right (mr) for spacing
        onClick={handleViewListings}  // Call the function to navigate
      >
        {t('viewListings')}  {/* Translated text for button */}
      </Button>

      {/* Add a button to navigate to the Customer Analytics page */}
      <Button
        variant="contained"
        color="secondary"  // You can use a different color for the Analytics button
        sx={{ mb: 2 }}
        onClick={handleViewAnalytics}  // Call the function to navigate
      >
        {t('viewCustomerAnalytics')}  {/* Translated text for the analytics button */}
      </Button>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('email')}</TableCell>
              <TableCell>{t('name')}</TableCell>
              <TableCell>{t('role')}</TableCell>
              <TableCell align="right">{t('actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(filteredUsers) && filteredUsers.length > 0 ? (
              filteredUsers.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.first_name} {user.last_name}</TableCell>
                  <TableCell>{user.is_admin ? t('admin') : t('user')}</TableCell>
                  <TableCell align="right">
                    <IconButton color="primary" aria-label={t('edit')} onClick={() => handleEdit(user.id)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton color="secondary" aria-label={t('delete')} onClick={() => handleDelete(user.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  {t('noUsersFound')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

export default AdminDashboard;
