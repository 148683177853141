import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './WelcomePage.css'; // Import custom CSS

const translations = {
  en: {
    title: "Welcome to Homiego",
    description: "Your trusted platform for rental apartment listings in Germany",
    content: "At Homiego, we understand the challenges of searching for the perfect apartment. That's why we provide a platform where you can:",
    features: [
      "Easily search available apartments based on your preferences",
      "One-click application process",
      "Secure management of your rental applications",
      "Assistance with navigating the rental process in Germany"
    ],
    login: "Login",
    register: "Register"
  },
  de: {
    title: "Willkommen bei Homiego",
    description: "Ihre zuverlässige Plattform für Mietwohnungsanzeigen in Deutschland",
    content: "Bei Homiego verstehen wir die Herausforderungen der Wohnungssuche. Deshalb bieten wir Ihnen eine Plattform, auf der Sie:",
    features: [
      "Einfach verfügbare Wohnungen nach Ihren Vorlieben durchsuchen",
      "Bewerbungsprozess mit einem Klick",
      "Sichere Verwaltung Ihrer Mietanträge",
      "Hilfe bei der Navigation durch den Mietprozess in Deutschland"
    ],
    login: "Anmelden",
    register: "Registrieren"
  },
  ar: {
    title: "مرحبًا بك في Homiego",
    description: "منصتك الموثوقة لقوائم الشقق الإيجارية في ألمانيا",
    content: "في Homiego، نفهم التحديات التي تواجهك عند البحث عن الشقة المثالية. ولهذا نقدم لك منصة يمكنك من خلالها:",
    features: [
      "البحث بسهولة عن الشقق المتاحة حسب تفضيلاتك",
      "عملية تقديم طلب بنقرة واحدة",
      "إدارة آمنة لطلبات الإيجار الخاصة بك",
      "مساعدتك في التنقل في عملية الإيجار في ألمانيا"
    ],
    login: "تسجيل الدخول",
    register: "تسجيل"
  },
  es: {
    title: "Bienvenido a Homiego",
    description: "Tu plataforma de confianza para listas de apartamentos en alquiler en Alemania",
    content: "En Homiego, entendemos los desafíos de buscar el apartamento perfecto. Por eso te ofrecemos una plataforma en la que puedes:",
    features: [
      "Buscar fácilmente apartamentos disponibles según tus preferencias",
      "Proceso de solicitud con un clic",
      "Gestión segura de tus solicitudes de alquiler",
      "Asistencia con la navegación en el proceso de alquiler en Alemania"
    ],
    login: "Iniciar sesión",
    register: "Registrarse"
  },
  uk: {
    title: "Ласкаво просимо до Homiego",
    description: "Ваша надійна платформа для пошуку орендованих квартир у Німеччині",
    content: "У Homiego ми розуміємо труднощі пошуку ідеальної квартири. Ось чому ми надаємо платформу, де ви можете:",
    features: [
      "Легко шукати доступні квартири відповідно до ваших уподобань",
      "Процес подання заявки одним кліком",
      "Безпечне управління вашими орендними заявками",
      "Допомога в орієнтації в процесі оренди в Німеччині"
    ],
    login: "Увійти",
    register: "Зареєструватися"
  }
};

const WelcomePage = () => {
  const [language, setLanguage] = useState('en');
  const { title, description, content, features, login, register } = translations[language];

  return (
    <div className="welcome-container">
      <div className="language-selector">
        <select id="language" value={language} onChange={(e) => setLanguage(e.target.value)}>
          <option value="en">English</option>
          <option value="de">German</option>
          <option value="ar">Arabic</option>
          <option value="es">Spanish</option>
          <option value="uk">Ukrainian</option>
        </select>
      </div>

      <div className="content-wrapper">
        <div className="text-content">
          <h1 className="welcome-title">{title}</h1>
          <p className="welcome-description">{description}</p>
          <p className="welcome-content">{content}</p>
          <ul className="features-list">
            {features.map((feature, index) => (
              <li key={index}>{feature}</li>
            ))}
          </ul>
        </div>
        <div className="welcome-buttons">
          <Link to="/login" className="btn btn-primary">{login}</Link>
          <Link to="/register" className="btn btn-secondary">{register}</Link>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;