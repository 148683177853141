import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';  // Import useLocation to access the message
import { AuthContext } from './AuthContext';
import { useTranslation } from 'react-i18next';

const Login = () => {
  const location = useLocation();  // Retrieve the location to get the message
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const { login, resendVerification, resetPassword } = useContext(AuthContext);
  const navigate = useNavigate();

  // Handle success message passed from registration page (if any)
  useEffect(() => {
    if (location.state && location.state.message) {
      setSuccess(location.state.message);
    }
  }, [location.state]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setLoading(true);  // Start loading

    try {
      const response = await login(email, password);  // Get full response from login function
      setLoading(false);

      if (response) {
        const { is_admin, is_profile_complete } = response;
        
        // If user is an admin, redirect to the admin dashboard
        if (is_admin) {
          navigate('/admin-dashboard');
        } else {
          // If the user's profile is incomplete, redirect to the profile page with a message
          if (!is_profile_complete) {
            navigate('/profile', {
              state: { message: t('completeYourProfile') }
            });
          } else {
            // Otherwise, redirect to the customer dashboard
            navigate('/customer-dashboard');
          }
        }
      } else {
        setError(t('invalidCredentialsOrEmailIsNotConfirmed'));
      }
    } catch (error) {
      console.error("Login error:", error);
      setError(t('invalidCredentialsOrEmailIsNotConfirmed'));
      setLoading(false);
    }
  };

  const handleResendVerification = async () => {
    setError('');  // Reset any previous errors
    const success = await resendVerification(email);
    if (success) {
      setSuccess(t('verificationEmailResent'));
    } else {
      setError(t('errorResendingVerificationEmail'));
    }
  };

  const handleForgotPassword = async () => {
    setError('');  // Reset any previous errors
    const success = await resetPassword(email);
    if (success) {
      setSuccess(t('passwordResetEmailSent'));
    } else {
      setError(t('errorSendingPasswordResetEmail'));
    }
  };

  return (
    <div className="container">
      <form onSubmit={handleLogin} className="mt-4">
        <h2>{t('login')}</h2>
        {/* Display success message (if any) */}
        {success && <div className="alert alert-success">{success}</div>}
        {error && <div className="alert alert-danger">{error}</div>}
        
        <div className="mb-3">
          <label className="form-label">{t('email')}:</label>
          <input
            type="email"
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            disabled={loading}  // Disable input while loading
          />
        </div>
        <div className="mb-3">
          <label className="form-label">{t('password')}:</label>
          <input
            type="password"
            className="form-control"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            disabled={loading}  // Disable input while loading
          />
        </div>
        <button type="submit" className="btn btn-primary" disabled={loading}>
          {loading ? t('loading') : t('login')}
        </button>

        {/* Show the resend verification button only when the error indicates email not confirmed */}
        {error.includes(t('emailNotConfirmed')) && (
          <button type="button" className="btn btn-secondary mt-3" onClick={handleResendVerification}>
            {t('resendVerificationEmail')}
          </button>
        )}

        <div className="mt-3">
          <button type="button" className="btn btn-link" onClick={handleForgotPassword} disabled={loading}>
            {t('forgotPassword')}
          </button>
        </div>

        <div className="mt-3">
          <button type="button" className="btn btn-link" onClick={() => navigate('/register')} disabled={loading}>
            {t('register')}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Login;
