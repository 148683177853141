import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../App.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Filters from './Filters';  // Import the Filters component

const Listings = ({ showApplied }) => {
  const { t } = useTranslation();  // Initialize useTranslation for multi-language support
  const [listings, setListings] = useState([]);
  const [error, setError] = useState('');
  const [hasPaid, setHasPaid] = useState(false);
  const [filters, setFilters] = useState({
    min_price: '',
    max_price: '',
    min_size: '',
    max_size: '',
    min_rooms: '',
    max_rooms: '',
    wbs: '',
    location: []  // Add location filter
  });
  const placeholderImage = 'https://via.placeholder.com/150';
  const navigate = useNavigate();
  
  // Fallback URL in case environment variable isn't set
  const baseUrl = process.env.REACT_APP_API_BASE_URL || 'https://homiego.de/api';

  useEffect(() => {
    const fetchListings = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setError(t('noTokenFound'));  // Translation for error message
        return;
      }

      const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: filters
      };

      try {
        const response = await axios.get(`${baseUrl}/listings`, config);
        console.log('API Response:', response.data);  // Log API response for debugging

        if (response.data && response.data.listings) {
          const filteredListings = response.data.listings.filter(listing => listing.has_applied === showApplied);
          setListings(filteredListings.length > 0 ? filteredListings : []);
        } else {
          setListings([]);  // Fallback to empty array if listings are undefined
        }

        setHasPaid(response.data.has_paid);  // Correctly set hasPaid state
      } catch (error) {
        console.error(t('errorFetchingListings'), error);
        setError(t('errorFetchingListings'));
      }
    };

    fetchListings();
  }, [showApplied, filters, t, baseUrl]);

  const handleApply = async (listingUrl) => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    try {
      const response = await axios.post(`${baseUrl}/apply`, { listing_url: listingUrl }, config);
      switch (response.data.message) {
        case "already_applied":
          alert(t('alreadyApplied'));  // Translated alert
          break;
        case "gesobau_manual_application":
          alert(t('gesobauManual'));  // Translated alert
          break;
        case "howoge_manual_application":
          alert(t('howogeManual'));  // Translated alert
          break;
        default:
          alert(t('applicationSubmitted'));  // Translated success message
          setListings(listings.map(listing => listing.url === listingUrl ? { ...listing, has_applied: true } : listing));
      }
    } catch (error) {
      console.error('Error applying to listing:', error);
      alert(t('errorApplyingListing') + `: ${error.response.data.message}`);  // Translated error message with details
    }
  };

  const handlePayment = () => {
    navigate('/paypal-payment');
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const applyFilters = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      applied: true
    }));
  };

  return (
    <div className="container mt-4">
      <h2 className="mb-4">{showApplied ? t('appliedListings') : t('newListings')}</h2>
      {/* Display Filters regardless of whether listings are available */}
      <Filters filters={filters} handleFilterChange={handleFilterChange} applyFilters={applyFilters} setFilters={setFilters}/>

      {error && <p className="text-danger">{error}</p>}
      {hasPaid ? (
        <>
          {/* Listings Section */}
          {listings.length > 0 ? (
            <div className="row">
              {listings.map((listing, index) => (
                <div key={index} className="col-md-6 mb-4">
                  <div className="card h-100">
                    <img
                      src={listing.image_url || placeholderImage}
                      className="card-img-top"
                      alt={listing.title}
                      onError={(e) => { e.target.onerror = null; e.target.src = placeholderImage }}
                    />
                    <div className="card-body">
                      <h5 className="card-title">{listing.title}</h5>
                      <p className="card-text">{listing.description}</p>
                      <p className="card-text"><strong>{t('price')}:</strong> {listing.price}</p>
                      <p className="card-text"><strong>{t('size')}:</strong> {listing.size}</p>
                      <p className="card-text"><strong>{t('rooms')}:</strong> {listing.rooms}</p>
                      <a
                        href={listing.url}
                        className="btn btn-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('moreDetails')}
                      </a>
                      {!listing.has_applied && (
                        <button
                          className="btn btn-primary"
                          onClick={() => handleApply(listing.url)}
                        >
                          {t('apply')}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-center">{t('listingsComingSoon')}</p>
          )}
        </>
      ) : (
        <div>
          <p>{t('payForProduct')}</p>
          <button className="btn btn-primary" onClick={handlePayment}>{t('goToPayment')}</button>
        </div>
      )}
    </div>
  );
}

export default Listings;
