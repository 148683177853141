import React, { createContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // Initialize with null to handle loading state
  const [isAdmin, setIsAdmin] = useState(null);
  const navigate = useNavigate();
  
  const baseUrl = process.env.REACT_APP_API_BASE_URL || 'https://homiego.de/api';

  const logout = useCallback(async () => {
    try {
      await axios.post(`${baseUrl}/logout`, {}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}` 
        }
      });

      setIsAuthenticated(false);
      setIsAdmin(false);
      localStorage.removeItem('token');
      localStorage.removeItem('is_admin');
      delete axios.defaults.headers.common['Authorization'];

      navigate('/login');
    } catch (error) {
      console.error("Logout failed", error);
    }
  }, [navigate, baseUrl]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const adminStatus = localStorage.getItem('is_admin') === 'true';

    if (token) {
      setIsAuthenticated(true);
      setIsAdmin(adminStatus);
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      setIsAuthenticated(false);
      setIsAdmin(false);
    }

    const interceptor = axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('is_admin');
          delete axios.defaults.headers.common['Authorization'];
          logout();
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [logout]);

  const login = async (email, password) => {
    try {
      const response = await axios.post(`${baseUrl}/login`, { email, password });
      if (response.status === 200) {
        const { access_token, is_admin } = response.data;
        localStorage.setItem('token', access_token);
        localStorage.setItem('is_admin', is_admin);

        axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
        setIsAuthenticated(true);
        setIsAdmin(is_admin);

        return { access_token, is_admin };  
      } else {
        return false;
      }
    } catch (error) {
      console.error("Login failed:", error);
      return false;
    }
  };

  const register = async (formData) => {
    try {
      const response = await axios.post(`${baseUrl}/register`, formData);
      if (response.status === 201) {
        return true;
      }
      return false;
    } catch (error) {
      // Check if the error response has the "Email already registered" message
      if (error.response && error.response.status === 400 && error.response.data.message === "Email already registered") {
        return "Email already registered";  // Return the specific error message
      }
      console.error("Registration failed", error);
      return false;
    }
  };
  
  const resendVerification = async (email) => {
    try {
      const response = await axios.post(`${baseUrl}/resend_verification`, { email });
      return response.status === 200;
    } catch (error) {
      console.error("Resend verification email failed", error);
      return false;
    }
  };

  const resetPassword = async (email) => {
    try {
      const response = await axios.post(`${baseUrl}/forgot-password`, { email });
      return response.status === 200;
    } catch (error) {
      console.error("Password reset request failed", error.response?.data);
      return false;
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, isAdmin, login, register, resendVerification, resetPassword, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
