import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../App.css';
import { Pie, Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement, BarElement } from 'chart.js';
import { Container, Typography, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

// Registering the required components for Chart.js to work
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement, BarElement);

const CustomerAnalytics = () => {
  const { t } = useTranslation();
  const [analytics, setAnalytics] = useState(null);
  const [loading, setLoading] = useState(true);
  const baseUrl = process.env.REACT_APP_API_BASE_URL || 'https://homiego.de/api';

  useEffect(() => {
    const fetchAnalytics = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found');
        setLoading(false);
        return;
      }

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      try {
        const response = await axios.get(`${baseUrl}/admin/customers/analytics`, config);
        console.log(response.data); // Log the data to verify the API response
        setAnalytics(response.data);
      } catch (error) {
        console.error('Error fetching analytics', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAnalytics();
  }, [baseUrl]);

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <CircularProgress />
        <Typography>{t('loading')}</Typography>
      </Container>
    );
  }

  if (!analytics) {
    return <Typography>{t('noDataAvailable')}</Typography>;
  }

  // Chart Data for Total Customers
  const totalCustomersData = {
    labels: [t('Admin'), t('Customer')],
    datasets: [
      {
        data: [analytics.total_admins || 0, analytics.total_non_admins || 0],
        backgroundColor: ['#FF6384', '#36A2EB'],
      },
    ],
  };

  // Chart Data for Paid vs Non-Paid Customers
  const paidCustomersData = {
    labels: [t('Paid'), t('Not Paid')],
    datasets: [
      {
        data: [analytics.total_paid_customers || 0, analytics.total_non_paid_customers || 0],
        backgroundColor: ['#4BC0C0', '#FFCE56'],
      },
    ],
  };

  // Chart Data for Listings by Website
  const listingsByWebsiteData = {
    labels: Object.keys(analytics.listings_by_website || {}),
    datasets: [
      {
        label: t('Listings by Website'),
        data: Object.values(analytics.listings_by_website || {}),
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
      },
    ],
  };

  // Chart Data for Applications Over Time
  const applicationsOverTimeData = {
    labels: Object.keys(analytics.applications_over_time || {}),
    datasets: [
      {
        label: t('Applications Over Time'),
        data: Object.values(analytics.applications_over_time || {}),
        fill: false,
        backgroundColor: '#36A2EB',
        borderColor: '#36A2EB',
      },
    ],
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>
        {t('customerAnalytics')}
      </Typography>

      {/* Total Customers - Pie Chart */}
      <Typography variant="h6">{t('Total Customers')}</Typography>
      <Pie data={totalCustomersData} />

      {/* Paid vs Non-Paid Customers - Pie Chart */}
      <Typography variant="h6" sx={{ mt: 4 }}>{t('Paid vs Non-Paid Customers')}</Typography>
      <Pie data={paidCustomersData} />

      {/* Listings by Website - Bar Chart */}
      <Typography variant="h6" sx={{ mt: 4 }}>{t('Listings by Website')}</Typography>
      <Bar data={listingsByWebsiteData} />

      {/* Applications Over Time - Line Chart */}
      <Typography variant="h6" sx={{ mt: 4 }}>{t('Applications Over Time')}</Typography>
      <Line data={applicationsOverTimeData} />
    </Container>
  );
};

export default CustomerAnalytics;
