import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import useTranslation

import '../App.css';

const UserEdit = () => {
    const { t } = useTranslation(); // Initialize translation
    const { id } = useParams();
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        phone_number: '',
        number_of_persons: '',
        message: '',
        ishandycap: 'No',
        haswbs: 'No',
        wbs_expire_date: '',
        wbs_number_of_rooms: '',
        wbs_type: '',
        is_admin: 'No',  // Default to 'No'
        has_paid: 'No',  // Default to 'No'
        email_confirmed: 'No'  // Default to 'No'
    });
    const navigate = useNavigate();
    const baseUrl = process.env.REACT_APP_API_BASE_URL || 'https://homiego.de/api';



    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await axios.get(`${baseUrl}/admin/users/${id}`);
                setFormData({
                    ...response.data,
                    ishandycap: response.data.ishandycap ? 'Yes' : 'No',
                    haswbs: response.data.haswbs ? 'Yes' : 'No',
                    wbs_expire_date: response.data.wbs_expire_date ? response.data.wbs_expire_date.split('T')[0] : '',
                    is_admin: response.data.is_admin ? 'Yes' : 'No',
                    has_paid: response.data.has_paid ? 'Yes' : 'No',
                    email_confirmed: response.data.email_confirmed ? 'Yes' : 'No'
                });
            } catch (error) {
                console.error(t('fetchUserError'), error);  // Translated error message
            }
        };

        fetchUser();
    }, [id, t,baseUrl]);  // Add t to dependencies

    const handleInputChange = (e) => {
        const { name, value, type } = e.target;
        setFormData({ ...formData, [name]: type === 'radio' ? value : value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const dataToSend = {
            ...formData,
            ishandycap: formData.ishandycap === 'Yes',
            haswbs: formData.haswbs === 'Yes',
            is_admin: formData.is_admin === 'Yes',
            has_paid: formData.has_paid === 'Yes',
            email_confirmed: formData.email_confirmed === 'Yes'
        };

        if (dataToSend.wbs_expire_date === '') {
            dataToSend.wbs_expire_date = null;
        }

        try {
            await axios.put(`${baseUrl}/admin/users/${id}`, dataToSend);
            alert(t('userUpdated'));  // Translated success message
            navigate('/admin-dashboard');
        } catch (error) {
            console.error(t('updateUserError'), error);  // Translated error message
        }
    };

    const redirectToAppliedListings = () => {
        navigate(`/admin/user/${id}/applications`);
    };

    const redirectToPreferences = () => {
        navigate(`/admin/user/${id}/preferences`);
    };

    return (
        <div className="container">
            <div className="profile-container">
                <h2>{t('editUser')}</h2>  {/* Translated heading */}
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>{t('firstName')}</label>
                        <input
                            type="text"
                            className="form-control"
                            name="first_name"
                            value={formData.first_name}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>{t('lastName')}</label>
                        <input
                            type="text"
                            className="form-control"
                            name="last_name"
                            value={formData.last_name}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>{t('email')}</label>
                        <input
                            type="text"
                            className="form-control"
                            name="email"
                            value={formData.email}
                            readOnly  // This makes the email field read-only
                        />
                    </div>

                    <div className="form-group">
                        <label>{t('phoneNumber')}</label>
                        <input
                            type="text"
                            className="form-control"
                            name="phone_number"
                            value={formData.phone_number}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>{t('numberOfPersons')}</label>
                        <input
                            type="number"
                            className="form-control"
                            name="number_of_persons"
                            value={formData.number_of_persons}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>{t('message')}</label>
                        <textarea
                            className="form-control"
                            name="message"
                            value={formData.message}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>{t('handycap')}</label>
                        <div>
                            <label className="form-check-label me-3">
                                <input
                                    type="radio"
                                    className="form-check-input"
                                    name="ishandycap"
                                    value="Yes"
                                    checked={formData.ishandycap === 'Yes'}
                                    onChange={handleInputChange}
                                />
                                {t('yes')}
                            </label>
                            <label className="form-check-label">
                                <input
                                    type="radio"
                                    className="form-check-input"
                                    name="ishandycap"
                                    value="No"
                                    checked={formData.ishandycap === 'No'}
                                    onChange={handleInputChange}
                                />
                                {t('no')}
                            </label>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>{t('hasWBS')}</label>
                        <div>
                            <label className="form-check-label me-3">
                                <input
                                    type="radio"
                                    className="form-check-input"
                                    name="haswbs"
                                    value="Yes"
                                    checked={formData.haswbs === 'Yes'}
                                    onChange={handleInputChange}
                                />
                                {t('yes')}
                            </label>
                            <label className="form-check-label">
                                <input
                                    type="radio"
                                    className="form-check-input"
                                    name="haswbs"
                                    value="No"
                                    checked={formData.haswbs === 'No'}
                                    onChange={handleInputChange}
                                />
                                {t('no')}
                            </label>
                        </div>
                    </div>
                    {formData.haswbs === 'Yes' && (
                        <>
                            <div className="form-group">
                                <label>{t('wbsExpireDate')}</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="wbs_expire_date"
                                    value={formData.wbs_expire_date}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>{t('wbsNumberOfRooms')}</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    name="wbs_number_of_rooms"
                                    value={formData.wbs_number_of_rooms}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>{t('wbsType')}</label>
                                <select
                                    className="form-control"
                                    name="wbs_type"
                                    value={formData.wbs_type}
                                    onChange={handleInputChange}
                                >
                                    <option value="WBS140">WBS 140</option>
                                    <option value="WBS160">WBS 160</option>
                                    <option value="WBS180">WBS 180</option>
                                    <option value="WBS220">WBS 220</option>
                                </select>
                            </div>
                        </>
                    )}
                    <div className="form-group">
                        <label>{t('admin')}</label>
                        <div>
                            <label className="form-check-label me-3">
                                <input
                                    type="radio"
                                    className="form-check-input"
                                    name="is_admin"
                                    value="Yes"
                                    checked={formData.is_admin === 'Yes'}
                                    onChange={handleInputChange}
                                />
                                {t('yes')}
                            </label>
                            <label className="form-check-label">
                                <input
                                    type="radio"
                                    className="form-check-input"
                                    name="is_admin"
                                    value="No"
                                    checked={formData.is_admin === 'No'}
                                    onChange={handleInputChange}
                                />
                                {t('no')}
                            </label>
                        </div>

                    </div>
                    <div className="form-group">
    <label>{t('paid')}</label>  {/* Translated label for 'Paid' */}
    <div>
        <label className="form-check-label me-3">
            <input 
                type="radio" 
                className="form-check-input" 
                name="has_paid" 
                value="Yes" 
                checked={formData.has_paid === 'Yes'} 
                onChange={handleInputChange} 
            />
            {t('yes')}  {/* Translated 'Yes' */}
        </label>
        <label className="form-check-label">
            <input 
                type="radio" 
                className="form-check-input" 
                name="has_paid" 
                value="No" 
                checked={formData.has_paid === 'No'} 
                onChange={handleInputChange} 
            />
            {t('no')}  {/* Translated 'No' */}
        </label>
    </div>
</div>

<div className="form-group">
    <label>{t('emailConfirmed')}</label>  {/* Translated label for 'Email Confirmed' */}
    <div>
        <label className="form-check-label me-3">
            <input 
                type="radio" 
                className="form-check-input" 
                name="email_confirmed" 
                value="Yes" 
                checked={formData.email_confirmed === 'Yes'} 
                onChange={handleInputChange} 
            />
            {t('yes')}  {/* Translated 'Yes' */}
        </label>
        <label className="form-check-label">
            <input 
                type="radio" 
                className="form-check-input" 
                name="email_confirmed" 
                value="No" 
                checked={formData.email_confirmed === 'No'} 
                onChange={handleInputChange} 
            />
            {t('no')}  {/* Translated 'No' */}
        </label>
    </div>
</div>

<button type="submit" className="btn btn-primary">{t('save')}</button>  {/* Translated 'Save' */}
<button type="button" className="btn btn-secondary" onClick={() => navigate('/admin-dashboard')}>{t('cancel')}</button>  {/* Translated 'Cancel' */}
</form>
<div className="mt-4">
    <button className="btn btn-info me-2" onClick={redirectToAppliedListings}>{t('viewAppliedListings')}</button>  {/* Translated 'View Applied Listings' */}
    <button className="btn btn-info" onClick={redirectToPreferences}>{t('viewEditPreferences')}</button>  {/* Translated 'View and Edit Preferences' */}
</div>

            </div>
        </div>
    );
};

export default UserEdit;
