import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mt-5 pt-5">
      <h1 className="mb-4">Privacy Policy</h1>
      <p><strong>Effective Date:</strong> [Insert Date]</p>

      <h2>1. Introduction</h2>
      <p>
        HomieGo ("we," "us," or "our") is committed to protecting the privacy and security of your personal data. This privacy policy explains how we collect, use, disclose, and safeguard your information when you use our platform, including our website, mobile application, and other services (collectively, "Services"). Please read this policy carefully. If you do not agree with the terms of this privacy policy, please do not use our services.
      </p>

      <h2>2. Information We Collect</h2>
      <h3>2.1 Personal Data</h3>
      <p>
        We may collect personally identifiable information, including but not limited to:
        <ul>
          <li>Name</li>
          <li>Email address</li>
          <li>Phone number</li>
          <li>Date of birth</li>
          <li>Gender</li>
          <li>Payment information (including payment card details, PayPal, etc.)</li>
          <li>Government-issued documents (WBS details, identification numbers)</li>
          <li>Preferences for property listings</li>
        </ul>
      </p>

      <h3>2.2 Automatically Collected Data</h3>
      <p>
        We automatically collect certain data when you use our services, such as:
        <ul>
          <li>IP address</li>
          <li>Browser type and version</li>
          <li>Device information (such as device type, operating system)</li>
          <li>Usage data (such as how you interact with our site)</li>
          <li>Cookies and similar tracking technologies</li>
        </ul>
      </p>

      <h2>3. How We Use Your Information</h2>
      <p>
        HomieGo uses your data to:
        <ul>
          <li>Provide and improve our services</li>
          <li>Personalize user experience</li>
          <li>Communicate with you regarding your account, preferences, and transactions</li>
          <li>Process payments and fulfill requests</li>
          <li>Comply with legal obligations</li>
          <li>Prevent fraudulent activities</li>
          <li>Analyze usage to improve functionality</li>
          <li>Send marketing and promotional communications (with your consent)</li>
        </ul>
      </p>

      <h2>4. Legal Basis for Processing</h2>
      <p>
        We process your personal data based on one or more of the following legal grounds:
        <ul>
          <li><strong>Consent</strong>: You have provided explicit consent for data processing (e.g., for receiving marketing communications).</li>
          <li><strong>Contractual Necessity</strong>: Data processing is necessary for the performance of a contract (e.g., account creation and management).</li>
          <li><strong>Legal Obligation</strong>: We are required by law to process certain data.</li>
          <li><strong>Legitimate Interests</strong>: We process your data to further our legitimate interests (e.g., improving services and fraud prevention).</li>
        </ul>
      </p>

      <h2>5. Sharing and Disclosure of Data</h2>
      <p>
        We do not sell or rent your personal data. However, we may share your information under the following circumstances:
        <ul>
          <li><strong>Third-Party Service Providers</strong>: We work with third-party service providers to perform services on our behalf, such as payment processors (e.g., PayPal), data storage providers, marketing platforms, and analytics services.</li>
          <li><strong>Legal Requirements</strong>: We may disclose your data if required to do so by law, such as in response to a court order, government request, or to comply with other legal obligations.</li>
          <li><strong>Business Transfers</strong>: In the event of a merger, acquisition, or sale of all or a portion of our business, your personal data may be transferred as part of that transaction.</li>
        </ul>
      </p>

      <h2>6. Data Security</h2>
      <p>
        We take the security of your data seriously and implement reasonable administrative, technical, and physical safeguards to protect your personal information from unauthorized access, use, disclosure, alteration, or destruction.
      </p>

      <h2>7. Data Retention</h2>
      <p>
        We retain your personal information for as long as it is necessary to provide our services, comply with legal obligations, resolve disputes, and enforce our agreements. We will securely delete or anonymize your data when it is no longer required for these purposes.
      </p>

      <h2>8. Your Rights</h2>
      <p>
        Under applicable data protection laws (e.g., GDPR), you have the following rights:
        <ul>
          <li><strong>Right to Access</strong>: You have the right to request a copy of the personal data we hold about you.</li>
          <li><strong>Right to Rectification</strong>: You have the right to request that we correct any inaccuracies in your personal data.</li>
          <li><strong>Right to Erasure</strong>: You have the right to request the deletion of your personal data under certain circumstances.</li>
          <li><strong>Right to Restriction of Processing</strong>: You can request that we limit how we use your personal data under certain circumstances.</li>
          <li><strong>Right to Data Portability</strong>: You have the right to receive your personal data in a structured, commonly used, and machine-readable format.</li>
          <li><strong>Right to Object</strong>: You have the right to object to the processing of your personal data in certain situations.</li>
          <li><strong>Right to Withdraw Consent</strong>: Where we rely on your consent to process your data, you have the right to withdraw your consent at any time.</li>
        </ul>
      </p>

      <h2>9. International Data Transfers</h2>
      <p>
        We may transfer your data to servers located outside of the European Union (EU) or European Economic Area (EEA). In such cases, we ensure that appropriate safeguards are in place, such as the use of Standard Contractual Clauses (SCCs) or other legal mechanisms to protect your data.
      </p>

      <h2>10. Cookies and Tracking Technologies</h2>
      <p>
        We use cookies and similar tracking technologies to enhance your experience on our platform. For more details, please review our <strong>Cookie Policy</strong>.
      </p>

      <h2>11. Changes to This Privacy Policy</h2>
      <p>
        We may update this privacy policy from time to time. We will notify you of any material changes by posting the new privacy policy on our website or by sending you a notification. The effective date of the latest version will be indicated at the top of this policy.
      </p>

      <h2>12. Contact Us</h2>
      <p>
        If you have any questions or concerns about this privacy policy or our data practices, please contact us at:
        <br />
        **HomieGo**
        <br />
        Email: [privacy@homiego.de]
        <br />
        Address: [Insert Company Address]
      </p>
    </div>
  );
};

export default PrivacyPolicy;
