import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const EmailVerification = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const baseUrl = process.env.REACT_APP_API_BASE_URL || 'https://homiego.de/api';


  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await axios.get(`${baseUrl}/confirm_email/${token}`);
        if (response.status === 200) {
          setMessage('Email verified successfully! You can now login.');
        }
      } catch (error) {
        setMessage('Email verification failed. Please try again.');
      }
    };
    verifyEmail();
  }, [token,baseUrl]);

  return (
    <div className="container mt-5 pt-4">
      <div className="alert alert-info">{message}</div>
      {message.includes('successfully') && (
        <button className="btn btn-primary" onClick={() => navigate('/login')}>Go to Login</button>
      )}
    </div>
  );
};

export default EmailVerification;
