import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Container,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    FormControlLabel,
    TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next'; // For multi-language support

const AdminListings = () => {
    const { t } = useTranslation();  // Initialize useTranslation
    const [listings, setListings] = useState([]);
    const [websites, setWebsites] = useState([]);  // State to store all websites
    const [selectedWebsite, setSelectedWebsite] = useState('');  // Dropdown selected value
    const [wbsFilter, setWbsFilter] = useState(false);  // Checkbox state for WBS filter
    const [minPrice, setMinPrice] = useState('');  // State for minimum price filter
    const [maxPrice, setMaxPrice] = useState('');  // State for maximum price filter
    const [minRooms, setMinRooms] = useState('');  // State for minimum rooms filter
    const [maxRooms, setMaxRooms] = useState('');  // State for maximum rooms filter
    const [listingsByWebsite, setListingsByWebsite] = useState({});
    const [totalListings, setTotalListings] = useState(0);  // State to store total number of listings
    const [error, setError] = useState('');
    const baseUrl = process.env.REACT_APP_API_BASE_URL || 'https://homiego.de/api';

    useEffect(() => {
        const fetchListings = async () => {
            const token = localStorage.getItem('token');
            if (!token) {
                setError(t('noTokenFound'));  // Error if token is missing
                return;
            }
    
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
    
            try {
                const response = await axios.get(`${baseUrl}/admin/listings`, config);
                const websitesResponse = await axios.get(`${baseUrl}/admin/websites`, config);  // Fetch websites

                console.log('API Response:', response.data);  // Log for debugging
                
                if (Array.isArray(response.data)) {
                    setListings(response.data);  // Set listings state
                    setWebsites(websitesResponse.data);  // Set websites state
                    setTotalListings(response.data.length);  // Set total number of listings
                    calculateListingsByWebsite(response.data);  // Calculate listings by website
                } else {
                    console.error('Unexpected response format:', response.data);
                    setListings([]);  // Fallback to empty array if response is not as expected
                }
            } catch (error) {
                console.error(t('errorFetchingListings'), error);
                setError(t('errorFetchingListings'));
            }
        };
    
        fetchListings();
    }, [t, baseUrl]);

    // Calculate how many listings each website has
    const calculateListingsByWebsite = (listings) => {
        if (!Array.isArray(listings)) {
            console.error('Listings is not an array:', listings);
            return;
        }

        const countByWebsite = {};
        listings.forEach((listing) => {
            const website = listing.website_name;  // Use website_name from the response
            if (!countByWebsite[website]) {
                countByWebsite[website] = 0;
            }
            countByWebsite[website] += 1;
        });
        setListingsByWebsite(countByWebsite);
    };

    // Filter listings based on selected website, WBS status, price, and rooms
    const filteredListings = listings.filter((listing) => {
        const matchesWebsite = selectedWebsite === '' || listing.website_name === selectedWebsite;
        const matchesWbs = !wbsFilter || listing.wbs;
        const matchesMinPrice = minPrice === '' || listing.price >= parseFloat(minPrice);
        const matchesMaxPrice = maxPrice === '' || listing.price <= parseFloat(maxPrice);
        const matchesMinRooms = minRooms === '' || listing.rooms >= parseInt(minRooms, 10);
        const matchesMaxRooms = maxRooms === '' || listing.rooms <= parseInt(maxRooms, 10);
        return matchesWebsite && matchesWbs && matchesMinPrice && matchesMaxPrice && matchesMinRooms && matchesMaxRooms;
    });

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Typography variant="h4" component="h1" gutterBottom>
                {t('adminListings')}
            </Typography>

            {error && <Typography variant="h6" color="error">{error}</Typography>}

            {/* Show total number of listings */}
            <Typography variant="h6" component="h2" gutterBottom>
                {t('totalListings')}: {totalListings}
            </Typography>

            {/* Dropdown for filtering by website */}
            <FormControl fullWidth variant="outlined" sx={{ mb: 3 }}>
                <InputLabel>{t('selectWebsite')}</InputLabel>
                <Select
                    value={selectedWebsite}
                    onChange={(e) => setSelectedWebsite(e.target.value)}
                    label={t('selectWebsite')}
                >
                    <MenuItem value="">{t('allWebsites')}</MenuItem>
                    {websites.map((website) => (
                        <MenuItem key={website.id} value={website.name}>
                            {website.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {/* Checkbox for WBS filtering */}
            <FormControlLabel
                control={
                    <Checkbox
                        checked={wbsFilter}
                        onChange={() => setWbsFilter(!wbsFilter)}
                        color="primary"
                    />
                }
                label={t('filterWbs')}
            />

            {/* Filters for price range */}
            <TextField
                label={t('minPrice')}
                type="number"
                value={minPrice}
                onChange={(e) => setMinPrice(e.target.value)}
                variant="outlined"
                sx={{ mb: 3, mr: 2 }}
            />
            <TextField
                label={t('maxPrice')}
                type="number"
                value={maxPrice}
                onChange={(e) => setMaxPrice(e.target.value)}
                variant="outlined"
                sx={{ mb: 3, mr: 2 }}
            />

            {/* Filters for number of rooms */}
            <TextField
                label={t('minRooms')}
                type="number"
                value={minRooms}
                onChange={(e) => setMinRooms(e.target.value)}
                variant="outlined"
                sx={{ mb: 3, mr: 2 }}
            />
            <TextField
                label={t('maxRooms')}
                type="number"
                value={maxRooms}
                onChange={(e) => setMaxRooms(e.target.value)}
                variant="outlined"
                sx={{ mb: 3, mr: 2 }}
            />

            {/* Show count of listings by website */}
            {Object.keys(listingsByWebsite).length > 0 ? (
                <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
                    <Typography variant="h6" component="h2" gutterBottom>
                        {t('listingsByWebsite')}
                    </Typography>
                    {Object.entries(listingsByWebsite).map(([website, count]) => (
                        <Typography key={website}>
                            {t('website')}: {website} - {t('listingsCount')}: {count}
                        </Typography>
                    ))}
                </Paper>
            ) : null}

            {/* Listings Table */}
            {Array.isArray(filteredListings) && filteredListings.length > 0 ? (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('id')}</TableCell>
                                <TableCell>{t('title')}</TableCell>
                                <TableCell>{t('price')}</TableCell>
                                <TableCell>{t('size')}</TableCell>
                                <TableCell>{t('rooms')}</TableCell>
                                <TableCell>{t('address')}</TableCell>
                                <TableCell>{t('description')}</TableCell>
                                <TableCell>{t('wbs')}</TableCell>
                                <TableCell>{t('website')}</TableCell>
                                <TableCell>{t('postedDate')}</TableCell>
                                <TableCell>{t('hasApplied')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredListings.map((listing) => (
                                <TableRow key={listing.id}>
                                    <TableCell>{listing.id}</TableCell>
                                    <TableCell>{listing.title}</TableCell>
                                    <TableCell>{listing.price}</TableCell>
                                    <TableCell>{listing.size}</TableCell>
                                    <TableCell>{listing.rooms}</TableCell>
                                    <TableCell>{listing.address}</TableCell>
                                    <TableCell>{listing.description}</TableCell>
                                    <TableCell>{listing.wbs ? t('yes') : t('no')}</TableCell>
                                    <TableCell>{listing.website_name}</TableCell> {/* Use website_name */}
                                    <TableCell>{new Date(listing.posted_date).toLocaleDateString()}</TableCell>
                                    <TableCell>{listing.applied_count > 0 ? t('yes') : t('no')}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography variant="h6" color="textSecondary" align="center" sx={{ mt: 2 }}>
                    {t('noListingsAvailable')} {/* This text is shown if no listings are available */}
                </Typography>
            )}
        </Container>
    );
};

export default AdminListings;