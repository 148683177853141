import React from 'react';
import './Footer.css'; // Import your CSS for styling

const Footer = () => {
  return (
    <footer className="footer bg-dark text-light py-4">
      <div className="container">
        <div className="row">
          {/* Company Info Section */}
          <div className="col-md-4">
            <h5>Homiego</h5>
            <p>Your trusted partner in finding rental apartments across Germany.</p>
            <p>&copy; 2024 Homiego. All rights reserved.</p>
          </div>

          {/* Quick Links Section */}
          <div className="col-md-4">
            <h5>Quick Links</h5>
            <ul className="list-unstyled">
              <li><a href="/about-us" className="text-light">About Us</a></li>
              <li><a href="/contact" className="text-light">Contact Us</a></li>
              <li><a href="/faq" className="text-light">FAQ</a></li>
              <li><a href="/privacy-policy" className="text-light">Privacy Policy</a></li>
            </ul>
          </div>

          {/* Contact Information Section */}
          <div className="col-md-4">
            <h5>Contact</h5>
            <p>Email: <a href="mailto:service@homiego.de" className="text-light">service@homiego.de</a></p>
            <p>Phone: <a href="tel:+4917683217950" className="text-light">+49 176 83217950</a></p>
            <div className="social-icons">
              <a href="https://www.linkedin.com/in/king-shawamreh/" className="text-light me-2"><i className="fab fa-linkedin"></i></a>
               </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
