import React, { useEffect, useContext, useRef, useCallback, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthContext } from './AuthContext';
import '../App.css';

const NavBar = () => {
  const { isAuthenticated, logout } = useContext(AuthContext);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location
  const navbarRef = useRef(null);
  const togglerRef = useRef(null);
  const isAdmin = localStorage.getItem('is_admin') === 'true';
  const [activeLink, setActiveLink] = useState(location.pathname); // State for active link

  // Function to switch the language
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const closeNavbar = useCallback(() => {
    const navbarCollapse = document.getElementById('navbarNav');
    if (navbarCollapse.classList.contains('show')) {
      togglerRef.current.click();
    }
  }, []);

  const handleClickOutside = useCallback((event) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      closeNavbar();
    }
  }, [closeNavbar]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  const handleLogout = () => {
    logout();
    navigate('/login');
    closeNavbar();
  };

  const renderAdminLinks = () => (
    <li className="nav-item">
      <Link
        className={`nav-link ${activeLink === '/admin-dashboard' ? 'active' : ''}`} 
        to="/admin-dashboard" 
        onClick={() => { setActiveLink('/admin-dashboard'); closeNavbar(); }}
      >
        {t('adminDashboard')}
      </Link>
    </li>
  );

  const renderCustomerLinks = () => (
    <>
      <li className="nav-item">
        <Link
          className={`nav-link ${activeLink === '/customer-dashboard' ? 'active' : ''}`} 
          to="/customer-dashboard" 
          onClick={() => { setActiveLink('/customer-dashboard'); closeNavbar(); }}
        >
          {t('customerDashboard')}
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className={`nav-link ${activeLink === '/preferences' ? 'active' : ''}`} 
          to="/preferences" 
          onClick={() => { setActiveLink('/preferences'); closeNavbar(); }}
        >
          {t('preferences')}
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className={`nav-link ${activeLink === '/profile' ? 'active' : ''}`} 
          to="/profile" 
          onClick={() => { setActiveLink('/profile'); closeNavbar(); }}
        >
          {t('profile')}
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className={`nav-link ${activeLink === '/applied-listings' ? 'active' : ''}`} 
          to="/applied-listings" 
          onClick={() => { setActiveLink('/applied-listings'); closeNavbar(); }}
        >
          {t('appliedListings')}
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className={`nav-link ${activeLink === '/notifications' ? 'active' : ''}`} 
          to="/notifications" 
          onClick={() => { setActiveLink('/notifications'); closeNavbar(); }}
        >
          {t('notifications')}
        </Link>
      </li>
    </>
  );

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top" ref={navbarRef}>
      <div className="container-fluid">
        <Link className="navbar-brand d-flex align-items-center" to={isAuthenticated ? (isAdmin ? "/admin-dashboard" : "/customer-dashboard") : "/"}>
          <img src="/logo512.png" alt="HomieGo Logo" className="d-inline-block align-top" style={{ height: '70px', width: '70px', marginRight: '1px' }} />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          ref={togglerRef}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            {!isAuthenticated ? (
              <>
                <li className="nav-item">
                  <Link className={`nav-link ${activeLink === '/login' ? 'active' : ''}`} to="/login" onClick={() => { setActiveLink('/login'); closeNavbar(); }}>
                    {t('login')}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={`nav-link ${activeLink === '/register' ? 'active' : ''}`} to="/register" onClick={() => { setActiveLink('/register'); closeNavbar(); }}>
                    {t('register')}
                  </Link>
                </li>
              </>
            ) : (
              <>
                {isAdmin ? renderAdminLinks() : renderCustomerLinks()}
                <li className="nav-item">
                  <button className="btn btn-link nav-link" onClick={handleLogout}>
                    {t('logout')}
                  </button>
                </li>
              </>
            )}
            {/* Language Switcher */}
            <li className="nav-item dropdown">
              <button
                className="btn btn-link nav-link dropdown-toggle"
                id="navbarDropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {t('language')}
              </button>

              <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <li>
                  <button className="dropdown-item" onClick={() => changeLanguage('en')}>English</button>
                </li>
                <li>
                  <button className="dropdown-item" onClick={() => changeLanguage('de')}>Deutsch</button>
                </li>
                <li>
                  <button className="dropdown-item" onClick={() => changeLanguage('ar')}>العربية</button>
                </li>
                <li>
                  <button className="dropdown-item" onClick={() => changeLanguage('uk')}>Українська</button>
                </li>
                <li>
                  <button className="dropdown-item" onClick={() => changeLanguage('es')}>Español</button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
